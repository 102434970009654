import { template as template_2192a01f603c42a5b4c138109f583d44 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PasskeyLoginButton = template_2192a01f603c42a5b4c138109f583d44(`
  <DButton
    @action={{@passkeyLogin}}
    @icon="user"
    @label="login.passkey.name"
    class="btn-social passkey-login-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PasskeyLoginButton;
