import { template as template_442f330b4a1e488e9b81507e2b54d81c } from "@ember/template-compiler";
const FKControlMenuContainer = template_442f330b4a1e488e9b81507e2b54d81c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
