import { template as template_4ff59a8c86b744b79e378d3336356534 } from "@ember/template-compiler";
const FKText = template_4ff59a8c86b744b79e378d3336356534(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
