import { template as template_2fa4458b409a4ee69b92c0083f102be1 } from "@ember/template-compiler";
const FKLabel = template_2fa4458b409a4ee69b92c0083f102be1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
